document.getElementById('about-button')?.addEventListener('click', () => {
  document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('services-button')?.addEventListener('click', () => {
  document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('contacts-button')?.addEventListener('click', () => {
  document.getElementById('contacts').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('about-button-mob')?.addEventListener('click', () => {
  document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('services-button-mob')?.addEventListener('click', () => {
  document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('contacts-button-mob')?.addEventListener('click', () => {
  document.getElementById('contacts').scrollIntoView({ behavior: 'smooth' });
});

const mobileMenu = document.querySelector('.mobmenu');
const openMenuBtn = document.querySelector('.open-mobmenu-btn');
const closeMenuBtn = document.querySelector('.close-mobmenu-btn');
const mobMenuNavButtons = document.querySelectorAll('.mobmenu-nav__button');

const toggleMenu = () => {
  mobileMenu.classList.toggle('is-open');
  const isMenuOpen = mobileMenu.classList.contains('is-open');

  isMenuOpen
    ? document.body.classList.add('is-open-for-body')
    : document.body.classList.remove('is-open-for-body');
};

openMenuBtn.addEventListener('click', toggleMenu);
closeMenuBtn.addEventListener('click', toggleMenu);
mobMenuNavButtons.forEach(btn => btn.addEventListener('click', toggleMenu));

window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
  if (!e.matches) return;
  mobileMenu.classList.remove('is-open');
  document.body.classList.remove('is-open-for-body');
});

openMenuBtn.addEventListener('click', toggleMenu);
closeMenuBtn.addEventListener('click', toggleMenu);
mobMenuNavButtons.forEach(btn => btn.addEventListener('click', toggleMenu));

window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
  if (!e.matches) return;
  mobileMenu.classList.remove('is-open');
  openMenuBtn.setAttribute('aria-expanded', false);
  bodyScrollLock.enableBodyScroll(document.body);
});
